.login-title {
  letter-spacing: 10px;
  color: white;
}

.login-banner {
  padding: 40px 0;
}

.login-btn {
  background-color: black !important;
  color: #ebe23b !important;
  font-weight: bold !important;
}