body {
  letter-spacing: 1px;
}

p,
a {
  font-family: 'Open Sans', sans-serif;
}

h5,
h3,
h2,
h1,
h4 {
  font-family: 'Staatliches', cursive;
  letter-spacing: 3px;
  font-weight: 700 !important;
}

.content-container {
  margin-bottom: 60px;
  padding: 80px 0;
}

.page-header {
  padding-bottom: 40px;
}

.bold-label-text {
  font-weight: 700;
}

.success-text {
  font-weight: 700;
  color: #00e800;
}

.warn-text {
  font-weight: 700;
  color: red;
}

.warn-bg {
  background-color: #ffebeb !important;
}

.minimal-layout {
  height: 100vh;
  background-color: #424242;
}
