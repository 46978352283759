.navbar {
  background-color: #000000;

  a {
    color: #ebe23b;
    font-weight: 600;
    font-family: 'Staatliches', cursive;
    letter-spacing: 2px;

    &:hover {
      color: white;
    }
  }
}

.navbar-toggler-icon {
  border: 1px solid yellow;
  border-radius: 5px;
}