.bulk-sync-container {
  margin-bottom: 60px;
}

.bulk-sync-cards {
  margin-top: 30px;
  width: 100%;
  padding: 2em;
  box-shadow: 10px 10px 10px #d9d9d9;
}

.details-box {
  border: 1px solid #4d4d4d;
  max-width: 500px;
  /* text-align: center; */
  margin: 0 auto;
  padding: 20px 10px;
  border-radius: 10px;
  background-color: #8ee6ac;
  color: #4d4d4d;
  letter-spacing: 1px;
  font-weight: bold;
}

.cascade-logo {
  width: 150px;
}

.logo-120 {
  width: 120px;
  padding-bottom: 5px;
}
